@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Reg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-BoldIt.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ZEISS Frutiger Next';
  src: url('/assets/fonts/ZEISSFrutigerNextUI-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box !important;
  @apply font-frutiger-next;
}

@layer base {
  input:disabled {
    background: transparent !important;
    background-color: transparent !important;
  }

  p {
    @apply whitespace-pre-wrap;
  }
}

@layer components {
  .debug-mode {
    @apply outline-1 outline outline-red;
  }

  .input-field {
    @apply border-b-1 border-b-zeiss-grey h-12 w-24 outline-none focus:text-black focus:border-b-black pr-8 disabled:text-zeiss-grey disabled:border-b-zeiss-grey;
  }

  .input-field-unit {
    @apply absolute right-0 top-1/2 -translate-y-1/2 peer-focus:text-black peer-disabled:text-zeiss-grey peer-disabled:bg-white;
  }

  .iosfix {
    @apply absolute top-0 bottom-0 left-0 right-0;
  }

  .subtitle {
    @apply font-semibold text-[#606A76] text-sm;
  }

  .subtitle-summary {
    @apply font-semibold text-subtitle-summary text-sm;
  }

  .info-summary {
    @apply font-normal text-info-summary text-sm;
  }
}
